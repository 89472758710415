export function toArray(value) {
  if (Array.isArray(value)) {
    return value;
  }

  return value !== undefined ? [value] : [];
}
export var isClient = typeof window !== 'undefined' && window.document && window.document.documentElement;
/** Is client side and not jsdom */

export var isBrowserClient = process.env.NODE_ENV !== 'test' && isClient;
export function hasValue(value) {
  return value !== undefined && value !== null;
}